import React from 'react';
import { clsx } from 'clsx';
import { IconsType } from './types';
import styles from './Icons.module.scss';

export interface IconProps
  extends Omit<React.HTMLAttributes<HTMLSpanElement>, 'children'> {
  name: IconsType;
  fontSize?: number | string;
  color?: string;
}

export function Icon({
  name,
  fontSize,
  color,
  className,
  style,
  ...rest
}: IconProps) {
  return (
    <i
      className={clsx(styles[`icon-${name}`], styles.icon, className)}
      style={{ ...style, fontSize, color }}
      {...rest}
    />
  );
}
